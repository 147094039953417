import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../components/Button";
import Layout from "../components/layout";
import OrcamentoImage from "../images/Orcamento.jpg";
import SEO from "../components/seo";
//import emailjs from "emailjs-com";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Orcamento() {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    if(data.user_name == "" || data.user_email == "" || data.user_phone == "" || data.user_message == "")
    {
      notifyError()
    }
    else
    {
      notifySucess()
      setFormData({
        user_name: data.user_name,
        user_message: data.user_message,
        user_email: data.user_email,
        user_phone: data.user_phone
      })
    } 
    console.log(data)
  };

  const [formData, setFormData] = useState({
    user_name: "",
    user_message: "",
    user_email: "",
    user_phone: ""
  })

  useEffect(() => {
    // sendEmail(formData)
    console.log("a")
  }, [formData])

  // const sendEmail = (data) => {
  //   emailjs.send('service_bw2xxcj', 'orcamento_email', data, 'UCeBqYi_IGlapqxZ_')
  // };
  const notifySucess = () => toast(
    <><h4 className="font-bold text-center base-golden-center mb-4">Orçamento enviado!</h4><div>Em breve, nosso setor comercial entrará em contato.</div></>,
    {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
    }
  );
  const notifyError = () => toast(
    <><h4 className="font-bold text-center base-golden-center mb-4">Preencha o formulário!</h4></>,
    {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: true,
    }
  );

  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Orçamento"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${OrcamentoImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Orçamento
        </h1>
      </section>

      <div className="text-justify my-12 mx-3 md:mx-64">
        <h2 className="font-bold text-center base-golden-center mb-12">
          Peça um orçamento
        </h2>
        <div className="form">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <input
              name="user_name"
              ref={register}
              placeholder="Nome"
              className="my-5"
            />
            <input
              name="user_email"
              ref={register}
              placeholder="E-mail"
              type="email"
              className="my-5"
            />
            <input
              name="user_phone"
              ref={register}
              placeholder="Telefone"
              className="my-5"
            />
            <textarea
              type="textarea"
              name="user_message"
              rows={5}
              ref={register}
              placeholder="Mensagem"
            />
            <Button className="w-full my-5" type="submit">
              Solicitar
            </Button>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default Orcamento;
